<template>

  <!-- Edit Mode -->
  <event-info-edit
    v-if="isEdit"
    @onEdit="handleChangeEditMode"
    :content="content"
    :isEdit="isEdit"
    :saveEventInfo="saveEventInfo"
    :eventActive="eventActive"    
    :eventDate="eventDate"
    :eventTime="eventTime"
    :eventTimeStart="eventTimeStart"
    :eventLocation="eventLocation"
    :provinceList="provinceList"
    :eventProvince="eventProvince"
    :eventLang="eventLang"
    :eventShowRandomWish="eventShowRandomWish"
  />

  <!-- Show Mode -->
  <event-info-show
    v-else
    @onEdit="handleChangeEditMode"
    :content="content"
    :isEdit="isEdit"
    :eventActive="eventActive"
    :eventLocation="eventLocation"
    :eventDate="eventDate"
    :eventTime="eventTime"
    :eventTimeStart="eventTimeStart"
    :eventProvince="eventProvince"
    :eventLang="eventLang"
    :eventShowRandomWish="eventShowRandomWish"
  />
</template>

<script setup>
const props = defineProps([
  "content",
  "eventActive",
  "eventLocation",
  "eventProvince",
  "provinceList",
  "eventDate",
  "eventTime",
  "eventTimeStart",
  "eventLang",
  "eventShowRandomWish",
  "saveEventInfo",
]);

const isEdit = ref(false);

const handleChangeEditMode = (_isEdit) => {
  isEdit.value = _isEdit;
};
</script>
